import React, { Component } from "react";

import OuterCircle from "./OuterCircle";
import CenterCircle from "./CenterCircle";

import "./CircleMeeting.css";

/*
  Draws the whole circle meeting meaning the center circle and the outer circles around it.
*/
export default class CircleMeeting extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      centerPerson,
      circlesSession,
      msecLeftToSpeak,
      peopleInCircle,
      peopleInQueue,
      getVideoElm,
      startOutCircleTemporarilyPersonSpeak,
      isControlBarLikedClicked,
      isLikedPressed,
      participants,
    } = this.props;

    return (
      participants !== nextProps.participants ||
      centerPerson !== nextProps.centerPerson ||
      isLikedPressed !== nextProps.isLikedPressed ||
      circlesSession.amITheHost !== nextProps.circlesSession.amITheHost ||
      circlesSession.participants.participants.length !==
        nextProps.circlesSession.participants.participants.length ||
      circlesSession.speakers.queue.length !==
        nextProps.circlesSession.speakers.queue.length ||
      circlesSession.getAllParticipants().length !==
        nextProps.circlesSession.getAllParticipants().length ||
      circlesSession !== nextProps.circlesSession ||
      msecLeftToSpeak !== nextProps.msecLeftToSpeak ||
      peopleInCircle !== nextProps.peopleInCircle ||
      peopleInQueue !== nextProps.peopleInQueue ||
      getVideoElm !== nextProps.getVideoElm ||
      startOutCircleTemporarilyPersonSpeak.isActive !==
        nextProps.startOutCircleTemporarilyPersonSpeak.isActive ||
      startOutCircleTemporarilyPersonSpeak.duration !==
        nextProps.startOutCircleTemporarilyPersonSpeak.duration ||
      startOutCircleTemporarilyPersonSpeak.progress !==
        nextProps.startOutCircleTemporarilyPersonSpeak.progress ||
      isControlBarLikedClicked !== nextProps.isControlBarLikedClicked
    );
  }

  render() {
    const {
      centerPerson,
      circlesSession,
      msecLeftToSpeak,
      getVideoElm,
      startOutCircleTemporarilyPersonSpeak,
      isControlBarLikedClicked,
      isLikedPressed,
      participants,
    } = this.props;

    return (
      <div className="circleMeeting">
        <div className="pageLogo">
          <img alt="Circles" src="/resources/images/circle-logo.png" />
        </div>
        <div id="session">
          <CenterCircle
            person={
              centerPerson?.id !== circlesSession.me?.id
                ? centerPerson
                : undefined
            }
            circleOccupied={centerPerson?.id}
            msecLeftToSpeak={msecLeftToSpeak}
          />
          <div className={"outerCircles " + (centerPerson?.id ? "circleOccupied" : "")}>
            {participants.map((participant, index) => {
              return (
                <OuterCircle
                  key={participant.getId()}
                  person={
                    centerPerson?.id !== circlesSession.me?.id
                      ? participant === centerPerson
                        ? undefined
                        : participant
                      : participant
                  }
                  myPosition={index}
                  circlesSession={circlesSession}
                  speakerPosition={circlesSession.getSpeakerPosition(
                    participant
                  )}
                  isCenterOccupied={centerPerson ? true : false}
                  centerPerson={centerPerson}
                  isLikedPressed={isLikedPressed}
                  peopleInCircle={participants.length}
                  getVideoElm={getVideoElm}
                  startOutCircleTemporarilyPersonSpeak={
                    startOutCircleTemporarilyPersonSpeak
                  }
                  isControlBarLikedClicked={isControlBarLikedClicked}
                  isHost={this.props.isHost}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
