/*

  Circles Animation component.

  Draws circles animation full-screen in background.

*/
import React, { Component } from "react";
import "./CirclesAnimCanvas.css";

/*
  A wrapper around canvas with our drawing methods in it...
*/
class MyCanvas {
  // elementId - the id of the element onto which we mount ourselves
  constructor(elementId) {
    this.canvas = document.querySelector(`#${elementId}`);
    this.ctx = this.canvas.getContext("2d");
    this.resized();
  }

  // Draws a circle at the center of the screen with given radius, width & opacity
  drawCenterString(string, pixels, opacity) {
    this.ctx.font = pixels + "px Comfortaa";
    this.ctx.fillStyle = "rgba(256,256,256," + opacity + ")";
    this.ctx.textAlign = "center";
    this.ctx.fillText(string, 100, 100);
  }

  resized() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.canvas.setAttribute("width", width);
    this.canvas.setAttribute("height", height);

    this.midX = width / 2;
    this.midY = height / 2;
  }

  // Draws a circle at the center of the screen with given radius, width & opacity
  drawCenterCircle(radius, lineWidth, opacity) {
    this.ctx.beginPath();
    this.ctx.strokeStyle = "rgba(256,256,256," + opacity + ")";
    this.ctx.lineWidth = lineWidth;
    this.ctx.arc(this.midX, this.midY, radius, 0, 2 * Math.PI);
    this.ctx.stroke();
  }

  clear() {
    this.ctx.beginPath();
    this.ctx.rect(0, 0, window.innerWidth, window.innerHeight);
    this.ctx.fillStyle = "rgba(22,22,22,1)";
    this.ctx.fill();
  }
}

// Returns a random number between min and max
function getRandomArbitary(min, max) {
  return Math.random() * (max - min) + min;
}

/*
    Draws a simple circle growing outwards
*/
class SimpleCircle {
  constructor(animLengthMilis) {
    this.animLength = animLengthMilis;
    this.percentDone = 0;
    this.finished = false;
  }

  start() {
    this.start = performance.now();
    this.resize();
  }

  teardown() {}

  draw(canvas, timeNow, canvasResized) {
    const outerStrokeRadiusMultiplier = 0.22;
    const innerStrokeRadiusMultiplier = 0.09;
    const innerRadiusMultiplier = 0.7;

    if (timeNow < this.start) return; // we haven't started yet...

    if (this.finished)
      throw new Error("I'm already finished, why you trying to draw me?");
    if (canvasResized) {
      this.resize();
    } // If the canvas was resized, everything changes...

    const percentDone = (timeNow - this.start) / this.animLength;
    this.finished = percentDone > 1;
    if (this.finished) return;

    const radius = this.finishRadius * percentDone;
    let opacity = 1;
    if (percentDone > 0.6) {
      opacity = 1 - (percentDone - 0.6) / 0.4;
    }

    canvas.drawCenterCircle(
      radius * innerRadiusMultiplier,
      radius * innerStrokeRadiusMultiplier,
      opacity
    );
    canvas.drawCenterCircle(
      radius,
      radius * outerStrokeRadiusMultiplier,
      opacity
    );
  }

  // if the last draw() function took us to the end, we're finished. kill us please.
  isFinished() {
    return this.finished;
  }

  resize() {
    this.finishRadius = Math.max(window.innerWidth / 2, window.innerHeight / 2);
  }
}

/*
   The Animator

   holds a bunch of animation objects and redraws them...

*/
class Animator {
  constructor(canvas) {
    this.resized = false;
    this.items = [];
    this.canvas = canvas;
  }
  draw(now) {
    const isResized = this.resized;
    this.resized = false;
    // small race condition here...
    const finished = [];

    // clears the canvas... paint it black, I wanna paint it black! Black as night...
    this.canvas.clear();

    for (let key in this.items) {
      const item = this.items[key];
      if (item) {
        item.draw(this.canvas, now, isResized);
        if (item.isFinished()) {
          finished.push(item);
          delete this.items[key];
        }
      }
    }

    // Get rid of the animations we aren't using anymore.
    for (let key in finished) {
      const value = finished[key];
      if (value) {
        value.teardown();
        this.items[key] = undefined;
        delete this.items[key];
      }
    }
  }
  add(animation) {
    const now = Date.now();
    animation.start(now);
    this.items.push(animation);
  }
}

/*
    Components drawing a circles animation using 2D canvas
*/
export default class CirclesAnimCanvas extends Component {
  constructor(props) {
    super(props);

    this.myCanvas = undefined;

    const me = this;
    this.resizeListener = function(){me.windowResized()}
  }

  componentDidMount() {
    this.myCanvas = new MyCanvas("start-circles-anim");

    const animator = new Animator(this.myCanvas);
    this.animator = animator;

    function drawLoop(now) {
      requestAnimationFrame(drawLoop);
      animator.draw(now);
    }
    drawLoop(performance.now());

    /*
      Launches animations by adding them to the animator.
    */
    const avgSpeed = 6000;
    const launch = function () {
      const circle = new SimpleCircle(
        getRandomArbitary(avgSpeed * 0.5, avgSpeed * 1.4)
      );
      animator.add(circle);
    };
    window.setInterval(launch, getRandomArbitary(avgSpeed * 0.3, avgSpeed));

    // Kick it off by adding first animation
    animator.add(new SimpleCircle(getRandomArbitary(avgSpeed, avgSpeed)));
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener, false);
    // animation will keep playing if we do not cancel it
    cancelAnimationFrame(this.myCanvas);
    this.myCanvas = undefined;
    this.animator = undefined;
  }

  windowResized() {
    if(this.myCanvas) this.myCanvas.resized();
    if(this.animator) this.animator.resized = true;
  }

  render() {
    return <canvas id="start-circles-anim" />;
  }
}
