import React, { Component } from "react";
import { USER_CONTROL_ROUND, ROUND_TIME_NOLIMIT } from "./roundmode-settings";
import "./RoundModeControl.css";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Center button of round mode
 */
export default class RoundModeControl extends Component {
  constructor(props, context) {
    super(props, context);

    this.clickHandler = this.clickHandler.bind(this);
    this.keyDownListener = (event) => {
      if (
        event.keyCode === 32 &&
        this.props.roundMode === USER_CONTROL_ROUND.SPEAKING
      ) {
        this.clickHandler(event);
      }
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDownListener, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownListener, false);
  }

  clickHandler = (event) => {
    console.log("RoundModeControl stopSpeaking", this.props);
    this.props.circlesSession.leaveRoundTurn();

    // prevent the space from seeing the click and causing double click on me
    event.stopPropagation();
  };

  render() {
    if (
      !this.props.roundMode ||
      this.props.roundMode === undefined ||
      this.props.roundMode === null
    ) {
      return <h1>Round Mode UNKNOWN?! </h1>;
    }

    switch (this.props.roundMode) {
      case USER_CONTROL_ROUND.IN_QUEUE: {
        return (
          <div className="roundModeControl-inQueue">
            {this.props.circlesSession.mySpeakerPosition()}
          </div>
        );
      }
      case USER_CONTROL_ROUND.HAVE_SPOKEN: {
        return <div className="roundModeControl-haveSpoken" />;
      }
      case USER_CONTROL_ROUND.SPEAKING: {
        let progress;
        this.props.roundTime == ROUND_TIME_NOLIMIT
          ? // eslint-disable-line eqeqeq
            (progress = 100)
          : (progress =
              ((this.props.roundTime - this.props.msecLeftToSpeak) /
                this.props.roundTime) *
              100);

        /* this was the countdown const label = this.props.msecLeftToSpeak > 0 ? Math.round(this.props.msecLeftToSpeak / 1000) : '∞'; */
        return (
          <Box
            position="fixed"
            display="inline-flex"
            className="roundModeControl-speaking"
            onClick={this.clickHandler}
          >
            <CircularProgress
              variant="determinate"
              value={progress}
              size={"10vh"}
              thickness={20}
            />
          </Box>
        );
      }
      default: {
        return <h1>Round Mode Confused</h1>;
      }
    }
  }
}
