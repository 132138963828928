import React, { Component } from "react";
import PersonStreamCircle from "./PersonStreamCircle";
import ImageCircle from "./ImageCircle";
import "./CenterCircle.css";
import MoviePlayCircle from "./MoviePlayCircle";
import { RoomSettingsContext } from "./RoomSettingsContext";

/*
    Draws the inner circle, positioned & sized correctly based on current screen size
*/
export default class CenterCircle extends Component {
  static contextType = RoomSettingsContext;
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, height: window.innerHeight };
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    );
  }

  render() {
    const widthOfScreen = this.state.width;
    const heightOfScreen = this.state.height;
    const maxDiameter = Math.min(widthOfScreen, heightOfScreen);

    const streamPosition = {
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    };

    // Calculate size
    const heightAsPercentScreen = 0.6;
    const h = maxDiameter * heightAsPercentScreen;
    const w = h;
    const streamSize = {
      width: w,
      height: h,
    };

    const roomSettings = this.context;

    return (
      <div
        className={`centerCircle ${
          this.props.circleOccupied ? "mainOccupied" : "mainEmpty"
        }`}
      >
        <ImageCircle
          className="absolutePositionCircle"
          style={{ ...streamSize, ...streamPosition }}
          imageURL={roomSettings.centerImage}
        />
        {roomSettings.centerVideo && (
          <MoviePlayCircle
            className="absolutePositionCircle"
            style={{ ...streamSize, ...streamPosition }}
            streamSize={streamSize}
            videoURL={roomSettings.centerVideo}
            muted={this.props.circleOccupied ? true : false}
          />
        )}
        {this.props.person && (
          <PersonStreamCircle
            className="absolutePositionCircle"
            style={{ ...streamSize, ...streamPosition }}
            streamSize={streamSize}
            person={this.props.person}
            speakerPosition={-1}
          />
        )}
      </div>
    );
  }
}
