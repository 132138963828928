import React, { Component } from "react";
import "./Circle.css";

/*
    Draws an image in a circle
*/
export default class ImageCircle extends Component {
  render() {
    let imageBgStyle = {};
    if (this.props.imageURL) {
      imageBgStyle = {
        backgroundImage: "url(" + this.props.imageURL + ")",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      };
    }
    return (
      <div
        className={"emptyCircle imageCircle " + (this.props.className || "")}
        style={{ ...this.props.style, ...imageBgStyle }}
      ></div>
    );
  }
}
