/*
    Component for displaying list of scenes available in the system
*/

import React, { Component } from "react";
import { RoomSettingsContext } from "../RoomSettingsContext";
import { Clear, SettingsSystemDaydream } from "@mui/icons-material";
import "./SceneMenuItem.css";

import Button from "@mui/material/Button";

export default class SceneMenuItem extends Component {
  static contextType = RoomSettingsContext;

  setScene = (event) => this.context.setScene(event.currentTarget.value);

  clearScene = () => this.context.setScene();

  render() {
    if (this.context.scenes) {
      const sceneNames = Object.keys(this.context.scenes);
      return (
        <div className="menuItem sceneMenuItem">
          <div className="menuItemTitle">
            <SettingsSystemDaydream />
          </div>
          <Button
            className="sceneButton"
            variant={!this.context.scene ? "contained" : "outlined"}
            startIcon={<Clear />}
            size="small"
            onClick={this.clearScene}
          >
            no scene
          </Button>
          {sceneNames.map((name) => (
            <Button
              className="sceneButton"
              variant={this.context.scene === name ? "contained" : "outlined"}
              size="small"
              value={name}
              key={name}
              onClick={this.setScene}
            >
              {name}
            </Button>
          ))}
        </div>
      );
    } else {
      return <div />;
    }
  }
}
