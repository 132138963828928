import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import "./Devices.css";

// Device types must match openvidu types - I.e. do not change these string values.
export const DEVICE_TYPE = {
  MIC: "audioinput",
  CAMERA: "videoinput",
};
/*
    Selector for devices
 */
export class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: undefined,
      camera: localStorage.getItem(DEVICE_TYPE.CAMERA),
      microphone: localStorage.getItem(DEVICE_TYPE.MIC),
    };


  }

  componentDidMount() {
    this.props.circlesSession.listDevices().then((listedDevices) => {
        -console.log("constructor kust",listedDevices);
      this.setState({ devices: listedDevices }, () => {
        console.log("constructor pre sanify", this.state.devices,"camera=", localStorage.getItem(DEVICE_TYPE.CAMERA), "mic=", localStorage.getItem(DEVICE_TYPE.MIC));
        this.sanifySelectedDevices();
        console.log("constructor post sanify","camera=", localStorage.getItem(DEVICE_TYPE.CAMERA), "mic=", localStorage.getItem(DEVICE_TYPE.MIC));
      });
    });
  }

  /* 
    If we've disconnected a device, 
    then local storage could have value of non-existant device, 
    so need to make sure we're setting to something sensible.
  */
  sanifySelectedDevices() {
    let cameras = this.listDeviceByType(DEVICE_TYPE.CAMERA);
    if (!this.hasDevice(this.state.camera,cameras) && cameras.length > 0) {
      console.log("reseting camera: ", cameras[0].deviceId);
      this.setCamera(cameras[0].deviceId);
    }

    let mics = this.listDeviceByType(DEVICE_TYPE.MIC);
    if (!this.hasDevice(this.state.microphone,mics) && mics.length > 0) {
      console.log("reseting mic: ", mics[0].deviceId);
      this.setMicrophone(mics[0].deviceId);
    }
  }

  handleChangeCamera = (event) => this.setCamera(event.target.value);

  // returns true if the device exists in the deviceList
  hasDevice(toFind, devices) {
      let found = false;
      console.log("searching for:",toFind, "in:", devices);
      for (const [, device] of devices.entries()) {
        console.log("deviceId: ",device.deviceId)
        if (device.deviceId === toFind) {
          found = true;
        }
      }
    return found;
  }

  setCamera(deviceId) {
    localStorage.setItem(DEVICE_TYPE.CAMERA, deviceId);
    this.setState({ camera: deviceId });
    console.log("setCamera","camera", localStorage.getItem(DEVICE_TYPE.CAMERA), "mic", localStorage.getItem(DEVICE_TYPE.MIC))
  }

  handleChangeMicrophone = (event) => this.setMicrophone(event.target.value);

  setMicrophone(deviceId) {
    localStorage.setItem(DEVICE_TYPE.MIC, deviceId);
    this.setState({ microphone: deviceId });
  }

  /*
     Returns list of system AV input devices matching the specified type
  */
  listDeviceByType(type) {
    if (!this.state.devices) return []; // This happens as device listing is asynch

    const devices = this.state.devices.filter((device) => device.kind === type);

    // remove address at and to make look nicer
    for (const device of devices) {
      device.label = device.label.replace(/\(\w{4}:\w{4}\)/, "");
    }
    return devices;
  }

  render() {
    // VV 2020Nov14 tried using defaultValue below, but with no luck... something is broken in Form.Control
    // VV 2020Nov14 or something where the inital setting of the values, so we'll have to live with this...
    return (
      <div className="devices-form">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-video-camera"></i>
            </span>
          </div>
          <TextField
            onChange={this.handleChangeCamera}
            select
            variant="outlined"
            className="select-field"
            SelectProps={{
              native: true,
            }}
          >
            {this.listDeviceByType(DEVICE_TYPE.CAMERA).map((device, index) => (
              <option 
                key={index} 
                value={device.deviceId}
                selected={this.state.camera == device.deviceId} // eslint-disable-line eqeqeq
              >
                {device.label}
              </option>
            ))}
          </TextField>
        </div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-microphone"></i>
            </span>
          </div>
          <TextField
            onChange={this.handleChangeMicrophone}
            select
            variant="outlined"
            className="select-field"
            SelectProps={{
              native: true,
            }}
          >
            {this.listDeviceByType(DEVICE_TYPE.MIC).map((device, index) => (
              <option 
                key={index} 
                value={device.deviceId}
                selected={this.state.microphone == device.deviceId} // eslint-disable-line eqeqeq
              >
                {device.label.replace("Microphone", "")}
              </option>
            ))}
          </TextField>
        </div>
      </div>
    );
  }
}
