/*
    Component that floats in the middle
    For displaying status messages, alert, errors
 */
import React, { Component } from "react";
import "./CirclesDialog.css";
import CirclesAnimCanvas from "./CirclesAnimCanvas";
import VersionNo from "../VersionNo";

export default class CirclesDialog extends Component {
  render() {
    return (
      <div className={`circlesDialog floatCenter ${this.props.className}`}>
        <div className="circlesDialogRowContainer">
          <div className="circlesDialogChildren">{this.props.children}</div>
        </div>
        <VersionNo />
        <CirclesAnimCanvas id="start-circles-anim" />
      </div>
    );
  }
}
