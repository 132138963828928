/*
    Draws one Person in their seat i.e. one circle in the outer ring of (small) circles
    Draws one outer circle from the set, 
    positioned & sized correctly based on current screen size

*/
import React, { Component } from "react";
import PersonStreamCircle from "./PersonStreamCircle";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { RoomSettingsContext } from "./RoomSettingsContext";
import "./OuterCircle.css";

export default class OuterCircle extends Component {
  static contextType = RoomSettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      microphoneEnabled: this.props.person?.isMuted(),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { width, height, progress, microphoneEnabled } = this.state;
    const {
      person,
      centerPerson,
      myPosition,
      speakerPosition,
      peopleInCircle,
      isCenterOccupied,
      startOutCircleTemporarilyPersonSpeak,
      isControlBarLikedClicked,
      isLikedPressed,
      circlesSession,
    } = this.props;

    return (
      width !== nextState.width ||
      height !== nextState.height ||
      progress !== nextState.progress ||
      microphoneEnabled !== nextState.microphoneEnabled ||
      (person &&
        nextProps.person &&
        (person.id !== nextProps.person.id ||
          person.isLiked !== nextProps.person.isLiked ||
          person.isMuted() !== nextProps.person.isMuted() ||
          person.outerSpeaker !== nextProps.person.outerSpeaker)) ||
      (centerPerson &&
        nextProps.centerPerson &&
        (centerPerson.id !== nextProps.centerPerson.id ||
          centerPerson.isLiked !== nextProps.centerPerson.isLiked ||
          centerPerson.isMuted() !== nextProps.centerPerson.isMuted() ||
          centerPerson.outerSpeaker !== nextProps.centerPerson.outerSpeaker)) ||
      isControlBarLikedClicked !== nextProps.isControlBarLikedClicked ||
      circlesSession !== nextProps.circlesSession ||
      myPosition !== nextProps.myPosition ||
      speakerPosition !== nextProps.speakerPosition ||
      peopleInCircle !== nextProps.peopleInCircle ||
      isCenterOccupied !== nextProps.isCenterOccupied ||
      isLikedPressed !== nextProps.isLikedPressed ||
      startOutCircleTemporarilyPersonSpeak.isActive !==
        nextProps.startOutCircleTemporarilyPersonSpeak.isActive ||
      startOutCircleTemporarilyPersonSpeak.duration !==
        nextProps.startOutCircleTemporarilyPersonSpeak.duration ||
      startOutCircleTemporarilyPersonSpeak.progress !==
        nextProps.startOutCircleTemporarilyPersonSpeak.progress
    );
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    );

    // Listen to changes on the person, since these are coming over the wire somewhere...
    const myPerson = this.props.person;
    myPerson?.subscribe(() => {
      this.setState({ microphoneEnabled: myPerson?.isMuted() });
    });
  }

  /*
    Render the circle - with special cases for some combinations of
    people in the room.
  */
  render() {
    if (this.props.peopleInCircle === 1) {
      return this.renderOne();
    } else if (
      this.props.peopleInCircle === 2 &&
      !this.props.isCenterOccupied
    ) {
      return this.renderTwo();
    } else {
      return this.renderMulti();
    }
  }

  /*
    Special case rendering just ONE person in the room.
  */
  renderOne() {
    const widthOfScreen = this.state.width;
    const heightOfScreen = this.state.height;
    const maxDiameter = Math.min(widthOfScreen, heightOfScreen);
    const myDiameter = maxDiameter * 0.5;
    const left =
      widthOfScreen / 2 - myDiameter - myDiameter * 0.2 + myDiameter / 2;
    let streamPosition = {
      left: left > 0 ? left : 0,
      bottom: myDiameter * 0.2,
    };
    let streamSize = {
      width: myDiameter,
      height: myDiameter,
    };

    // If have no video stream - i.e. I'm a placeholder

    return this.renderCircleElement(streamPosition, streamSize, myDiameter);
  }

  /*
    Special case rendering just TWO people in the room.
  */
  renderTwo() {
    const widthOfScreen = this.state.width;
    const heightOfScreen = this.state.height;
    let streamSize = {};
    let streamPosition = {};
    const myDiameter = (0.8 * Math.max(widthOfScreen, heightOfScreen)) / 2;
    streamPosition = {
      top: (heightOfScreen - myDiameter) / 2,
    };

    const emptyHorizontalSpace = widthOfScreen - myDiameter * 2;
    const streamHorizontalOffestFromSide = emptyHorizontalSpace * 0.2;

    streamPosition.left =
      this.props.myPosition === 1
        ? streamHorizontalOffestFromSide + myDiameter / 2
        : streamHorizontalOffestFromSide + widthOfScreen / 2 + myDiameter / 2;
    streamSize = {
      width: myDiameter,
      height: myDiameter,
    };

    return this.renderCircleElement(streamPosition, streamSize, myDiameter);
  }

  renderMulti() {
    const heightOfScreen = this.state.height;
    let streamSize = {};
    let streamPosition = {};

    let angle =
      ((2 * Math.PI) / this.props.peopleInCircle) * this.props.myPosition;

    if (this.props.peopleInCircle === 3) {
      switch (this.props.myPosition) {
        case 0: {
          angle = -Math.PI / 4;
          break;
        }
        case 1: {
          angle = Math.PI / 6;
          break;
        }
        case 2: {
          angle = Math.PI;
          break;
        }
        default: {
          console.error("three people in circle but Im bigger? huh?");
          break;
        }
      }
    } else if (this.props.peopleInCircle === 4) {
      angle = angle - Math.PI / 4;
    } else {
      // to always start at top - somehow angle zero gives us to east...
      angle = angle - (0.8 * Math.PI) / 2;
    }

    // main size modifier based on participant count
    const sizeAsPercentOfScreenHeight = 0.4;

    // modify the circle diagonal size based on how many people participate
    let diagonalModifier = 1 - this.props.peopleInCircle * 0.05;
    if (diagonalModifier < 0.1) diagonalModifier = 0.1;

    const diagonal =
      heightOfScreen * sizeAsPercentOfScreenHeight * diagonalModifier;

    streamSize = {
      width: diagonal,
      height: diagonal,
    };

    const CalculationBasis =
      window.innerWidth > window.innerHeight
        ? window.innerHeight
        : window.innerWidth;

    const x = Math.cos(angle) * CalculationBasis * sizeAsPercentOfScreenHeight;
    const y = Math.sin(angle) * CalculationBasis * sizeAsPercentOfScreenHeight;

    const offsetLeft = (window.innerWidth - streamSize.width * 3) / 2;
    const offsetTop = (window.innerHeight - streamSize.width * 3) / 2;

    streamPosition.top = `${offsetTop + y + streamSize.width}px`;
    streamPosition.left = `${
      offsetLeft + x + streamSize.width + streamSize.width / 2
    }px`;

    return this.renderCircleElement(
      streamPosition,
      streamSize,
      streamSize.width
    );
  }

  renderCircleElement(streamPosition, streamSize, myDiameter) {
    const myId = document.cookie.split('_cr_id=')[1].split(';')[0];
    const {
      startOutCircleTemporarilyPersonSpeak: { isActive, personId, progress },
      person,
      speakerPosition,
      peopleInCircle,
      centerPerson,
      isControlBarLikedClicked,
      isLikedPressed,
      circlesSession,
      isHost,
    } = this.props;

    return person ? (
      <Box
        position="fixed"
        display="inline-flex"
        className={
          centerPerson?.id === person?.id ? "personBox isSpeaking" : "personBox"
        }
        style={{ ...streamPosition, ...streamSize }}
      >
        <PersonStreamCircle
          className="absolutePositionCircle"
          isSpeaking={centerPerson?.id === person?.id}
          person={person}
          streamSize={streamSize}
          speakerPosition={speakerPosition}
          peopleInCircle={peopleInCircle}
          isControlBarLikedClicked={isControlBarLikedClicked}
          isLikedPressed={isLikedPressed}
          centerPerson={centerPerson}
          circlesSession={circlesSession}
        />
        {person.id.split('_')[1] !== myId && isHost && <div className="kick-out" onClick={() => this.props.circlesSession.kickParticipant(person.id, this.context.roomName)} />}

        {isActive && personId === person.id && (
          <CircularProgress
            variant="determinate"
            value={progress}
            size={myDiameter}
            thickness={20}
          />
        )}
      </Box>
    ) : (
      <Box
        position="fixed"
        display="inline-flex"
        className="personEmptyBox"
        style={{ ...streamPosition, ...streamSize }}
      />
    );
  }
}
