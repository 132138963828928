/*
    States for central button in round mode
 */
export const USER_CONTROL_ROUND = {
  START_ROUND: "start_round",
  IN_QUEUE: "in_queue",
  SPEAKING: "speaking",
  HAVE_SPOKEN: "have_spoken",
};

/*
  The round which doesn't end until someone says stop
 */
export const ROUND_TIME_NOLIMIT = -1;
