/*
   Display Version No in bottom right.
 */
import React from "react";
import "./VersionNo.css";

import package_json from "../package.json"; // so we can get the version number...

export default function VersionNo() {
  return <div className="versionNo">{package_json.version}</div>;
}
