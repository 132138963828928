/*
	A queue of Person's who want to speak.

  The person at top of queue is the person currently speaking.
*/
export default class SpeakerQueue {
  constructor() {
    this.queue = [];
  }

  /*
		Adds Person to the speaking queue.
	*/
  add(person) {
    console.log("SpeakerQueue add", person);
    this.queue.push(person);
  }

  /*
    Replaces the speaking queue with the specified one.
  */
  replace(personCollection) {
    console.log("SpeakerQueue replace", personCollection);
    this.queue = [personCollection].flat();
  }

  // Returns the person in center or undefined if none...
  getCurrentSpeaker() {
    if (this.queue.length === 0) return;

    return this.queue[0];
  }

  /*
    return the number the specified person 
  */
  speakerOrder(person) {
    return this.queue.findIndex(
      (queuedPerson) => queuedPerson.getId() === person.getId()
    );
  }
  /*
		Returns how many people in speaking queue.
	*/
  length() {
    return this.queue.length;
  }

  /*
		Remove the specified person from the queue
	*/
  remove(person) {
    console.log("SpeakerQueue remove", person);

    const index = this.queue.indexOf(person);
    console.assert(index !== -1, "not found to remove?", person);

    if (index > -1) this.queue.splice(index, 1);
  }

  /*
    removes a person by stream 
  */
  removeById(id) {
    //remove them from speaker queue array
    const index = this.queue.findIndex(
      (queuedParticipant) => queuedParticipant.getId() === id
    );
    console.assert(index !== -1, "not found to remove?", id);

    if (index > -1) this.queue.splice(index, 1);
  }

  /*
    removes a person by stream if he is aged more than 4 hours
  */
  removeByIdAged(id) {
    // Get the current time and convert it to milliseconds
    const now = Date.now();

    //First find out if the participant is aged more than 4 hours
    const index = this.queue.findIndex(
      (queuedParticipant) => queuedParticipant.getId() === id
    );

    const timestamp = Number.parseInt(
      this.queue[index].getId().split("_")[1],
      10
    );
    const ageInMilliseconds = now - timestamp;
    const ageInHours = ageInMilliseconds / (1000 * 60 * 60);
    if (ageInHours > 4) {
      // Then remove the participant with the specified ID
      const index = this.queue.findIndex(
        (queuedParticipant) => queuedParticipant.getId() === id
      );
      console.assert(index !== -1, "not found to remove?", id);
    }
    if (index > -1) this.queue.splice(index, 1);
  }

  /*
    Sets the queue
  */
  setQueue(newQueue) {
    this.queue = newQueue;
  }

  indexOf(person) {
    return this.queue.findIndex(
      (speaker) => speaker.getId() === person.getId()
    );
  }

  /*
  	Returns true if the person is at the top of the speaking queue
  */
  isFirst(person) {
    return this.queue.length > 0
      ? this.queue[0].getId() === person.getId()
      : false;
  }

  /*
    Returns the JSON representation of this queueu, which is a JSON array of Person
  */
  toJSON() {
    return this.queue;
  }

  /*
    Returns all speakers - first is current speaker, then in order that should speak
  */
  getAll() {
    return this.queue;
  }
}
