/*
    React ccomponent providing the settings for a room from firebase, etc.

    Listens to live changes in firebase.
*/

import React, { Component } from "react";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { RoomSettingsContext } from "./RoomSettingsContext";
import { firebaseApp } from "../firebase";

export default class RoomSettingsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: this.props.roomName,
      setScene: this.setScene,
      isMounted: false,
      // connectionStatus: {},
    };

    this.roomSettingsDB = undefined;
    this.scenesDB = undefined;
    this.db = getDatabase(firebaseApp);
    this.roomSettingsRef = ref(this.db, `rooms/${this.props.roomName}`);
    //Sets up a listener for changes on scenes
    this.scenesRef = ref(this.db, `scenes`);
    // this.connectedRef = ref(this.db, ".info/connected");
    // this.connectionsRef = ref(this.db, `connections/${userId}`); // Replace `userId` with the appropriate user ID

    onValue(this.scenesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.scenesDB = data;
        this.updateState();
      }
    });

    //Sets up a listener for room settings changes on roomName
    onValue(this.roomSettingsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.roomSettingsDB = data;
        this.updateState();
      }
    });
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  /*
        Called to set the scene of the current room.
    */
  setScene = (sceneName) => {
    console.log("RoomSettingsProvider setting scene to:", sceneName);
    update(this.roomSettingsRef, { scene: sceneName });
  };
  /*
        Called when any scenes change
     */
  loadScenes = (snapshot) => {
    const data = snapshot.val();
    this.scenesDB = data;
    this.updateState();
  };
  /*
        Called when firebase room settings are changed.
    */
  onRoomSettings = (snapshot) => {
    const data = snapshot.val();
    this.roomSettingsDB = data;
    this.updateState();
  };

  updateState() {
    const newState = {
      scene: undefined,
      bgImage: undefined,
      bgVideo: undefined,
      centerImage: undefined,
      centerVideo: undefined,
    }; // need this to undefined anything that was removed
    if (this.roomSettingsDB !== undefined) {
      Object.assign(newState, this.roomSettingsDB); // Copy new values into the state
      // Scene overrides any room settings
      const scene = this.roomSettingsDB.scene;
      if (scene && this.scenesDB) {
        const sceneSettings = this.scenesDB[scene];
        if (sceneSettings.bgImage) {
          newState.bgVideo = undefined;
        }
        if (sceneSettings.centerImage) {
          newState.centerVideo = undefined;
        }
        Object.assign(newState, sceneSettings);
      }
    }
    if (this.state.isMounted) {
      this.setState({ scenes: this.scenesDB, ...newState });
    }
  }

  render() {
    return (
      <RoomSettingsContext.Provider value={this.state}>
        {this.props.children}
      </RoomSettingsContext.Provider>
    );
  }
}
