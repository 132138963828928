import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react"; // For error logging
import App from "./App";
import { CaptureConsole } from "@sentry/integrations";

import package_json from "../package.json"; // so we can get the version number...

Sentry.init({
  environment: process.env.NODE_ENV,
  release: "circles@" + package_json.version,
  integrations: [
    new CaptureConsole({
      levels: ["info", "warn", "error"],
    }),
  ],
  dsn: "https://da089b50eec54cb79c65d4b111a05b03@o415242.ingest.sentry.io/5306018",
});

const container = document.querySelector("#root");
const root = createRoot(container);
root.render(<App />);
