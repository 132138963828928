/*
    A creates a server connection faking what server should be doing, on the client side.

    This code should be on the server based on authentication & etc..
*/
import axios from "axios";
import { Stream, Subscriber } from "openvidu-browser";

const OPENVIDU_SERVER_URL = "https://video1.devadmin.com:443";
const OPENVIDU_SERVER_SECRET = "1_934AlphaToStart";
const CIRCLES_PREFIX = "c_r_les1972-"; // so that other clients doesn't randomly connect to our sessions.

const AUTH_HEADER = {
  headers: {
    Authorization: `Basic ${btoa(
      `OPENVIDUAPP:${OPENVIDU_SERVER_SECRET}`
    )}`,
    "Content-Type": "application/json",
  },
};

export default class ServerConnect {
  /**
   * --------------------------
   * SERVER-SIDE RESPONSIBILITY
   * --------------------------
   * These methods retrieve the mandatory user token from OpenVidu Server.
   * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
   * the API REST, openvidu-java-client or openvidu-node-client):
   *   1) Initialize a session in OpenVidu Server    (POST /api/sessions)
   *   2) Generate a token in OpenVidu Server        (POST /api/tokens)
   *   3) The token must be consumed in Session.connect() method
   */

  // returns a session token
  async getToken(sessionName) {
    console.log("ServerConnect getToken sessionName", sessionName);
    const session = await this._createSession(CIRCLES_PREFIX + sessionName);

    console.log("ServerConnect getToken session", session);
    const token = await this._createToken(session);

    console.log("ServerConnect getToken token", token);

    return token;
  }

  /* 
    returns a session to open vidu server
  */
  async _createSession(sessionId) {
    const data = JSON.stringify({ customSessionId: sessionId });

    try {
      const response = await axios.post(
        `${OPENVIDU_SERVER_URL}/openvidu/api/sessions`,
        data, AUTH_HEADER
      );
      return response.data.id;
    } catch (error) {
      console.log("_createSession got error:", error);

      // 409 error means the session already exists and is ready to use
      // see: https://github.com/OpenVidu/openvidu/issues/157
      if (error.response && error.response.status === 409) {
        console.log("_createSession session existed", sessionId);
        return sessionId;
      } else {
        throw new Error(
          "_createSession : Failed connection to OpenVidu Server creating session: " +
            error.message,
          error
        );
      }
    }
  }

  /* 
      Destroys the specified session

      see docs: https://docs.openvidu.io/en/stable/reference-docs/REST-API/#delete-session
  */
  async destroySession(sessionName) {
    const sessionId = CIRCLES_PREFIX + sessionName;
    try {
      await axios.delete(
        `${OPENVIDU_SERVER_URL}/openvidu/api/sessions/`+sessionId,
        AUTH_HEADER
      );
      return true;
    } catch (error) {
      console.log("destroySession got error:", error);
        throw new Error(
          "destroySession : Failed destroying session: " + sessionId +
            error.message,
          error
        );
    }
  }

  async kickParticipant(clientData, sessionName) {
    const sessionId = CIRCLES_PREFIX + sessionName;
    try {
      const participants = await axios.get(
        `${OPENVIDU_SERVER_URL}/openvidu/api/sessions/`+sessionId+"/connection",
        AUTH_HEADER
      );

      const participantId = participants.data.content.find(participant => JSON.parse(participant.clientData).clientData === clientData).connectionId;

      await axios.delete(
        `${OPENVIDU_SERVER_URL}/openvidu/api/sessions/`+sessionId+"/connection/"+participantId,
        AUTH_HEADER
      );

      console.log("kickParticipant success")
      return true;
    } catch (error) {
      console.log("kickParticipant got error:", error);
        throw new Error(
          "kickParticipant : Failed kicking participant: " + clientData,
          error
        );
    }
  }

  async _createToken(sessionId) {
    const data = JSON.stringify({ session: sessionId });
    const response = await axios.post(
      `${OPENVIDU_SERVER_URL}/openvidu/api/tokens`,
      data, AUTH_HEADER
    );

    return response.data.token;
  }
}

export async function fetchActiveConnections(sessionId) {
  const openviduUrl = "https://video1.devadmin.com:443";
  const openviduSecret = "1_934AlphaToStart";
  const response = await axios.get(
    `${openviduUrl}/openvidu/api/sessions/${sessionId}`,
    {
      headers: {
        Authorization: "Basic " + btoa("OPENVIDUAPP:" + openviduSecret),
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status >= 200 && response.status < 300) {
    const content = response?.data?.connections.content;
    const usersData =
      content &&
      content.map((connection) => ({
        clientData: JSON.parse(connection.clientData).clientData,
        userIp: connection.ip,
        userRole: connection.role,
        status: connection.status,
      }));

    return usersData;
  } else {
    throw new Error("Failed to fetch active connections");
  }
}

class CustomStream extends Stream {
  getRTCPeerConnection() {
    return this.webRtcPeer.peerConnection;
  }
}

export class CustomSubscriber extends Subscriber {
  constructor(streamManager, options) {
    super(streamManager, options);
  }

  subscribeToStream() {
    this.stream = new CustomStream(this.streamManager);
    this.stream.initWebRtcPeerReceive(this.options, (error) => {
      if (!error) {
        this.initWebRtcPeerReceiveResolve(this.stream);
      } else {
        this.initWebRtcPeerReceiveReject(error);
      }
    });
  }
}
