import React, { Component } from "react";

import "./NormalModeControl.css";

/*
    States for central button in normal mode

    (also CSS class on button - so don't change these strings!)
 */
const USER_ACTION = {
  GET_IN: "normalGetIn",
  QUEUE_UP: "normalQueueUp",
  LEAVE_QUEUE: "normalLeaveQueue",
  STOP_SPEAKING: "normalStopSpeaking",
  STOP_SPEAKING_SOMEONE_WAITING: "normalStopSpeakingSomeoneWaiting",
};

/*
    The Center button for controlling normal mode
 */
export default class NormalModeCenterControl extends Component {
  keyDownListener = (event) => {
    if (event.keyCode === 32) {
      this.clickHandler(event);
    }
  };

  componentDidMount() {
    console.log("--NormalModeControl componentDidMount", this.props);
    document.addEventListener("keydown", this.keyDownListener, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownListener, false);
  }

  getUserMode() {
    const session = this.props.circlesSession;
    if (session.amIinCenter()) {
      return session.speakingQueueLength() > 1
        ? USER_ACTION.STOP_SPEAKING_SOMEONE_WAITING
        : USER_ACTION.STOP_SPEAKING;
    }

    if (session.amIinQueue()) {
      return USER_ACTION.LEAVE_QUEUE;
    }
    return session.speakingQueueLength() > 0
      ? USER_ACTION.QUEUE_UP
      : USER_ACTION.GET_IN;
  }

  /* 
    Handles click in center (or keypress)
  */
  clickHandler = (event) => {
    console.log("NormalModeControl clickHandler");

    switch (this.getUserMode()) {
      case USER_ACTION.GET_IN:
      case USER_ACTION.QUEUE_UP: {
        this.props.circlesSession.enterSpeakers();
        break;
      }
      case USER_ACTION.LEAVE_QUEUE:
      case USER_ACTION.STOP_SPEAKING:
      case USER_ACTION.STOP_SPEAKING_SOMEONE_WAITING: {
        this.props.circlesSession.leaveSpeakers();
        break;
      }
      default: {
        break;
      }
    }

    // prevent the space from seeing the click and causing double click on me
    event.stopPropagation();
  };

  render() {
    return (
      <div
        className={`normalModePanel ${this.getUserMode()}`}
        onClick={this.clickHandler}
      ></div>
    );
  }
}
