/*
    Application modes
 */
export const APPLICATION_MODE = {
  HELLO: "hello",
  DEVICE_INIT: "deviceinit",
  START_DIALOG: "opendialog",
  SESSION_START: "sessionstart",
  LOADING: "loading",
  NORMAL: "normal",
  ROUND: "round",
  GOODBYE: "goodbye",
};
