import React, { Component } from "react";
import { ROUND_TIME_NOLIMIT } from "./roundmode-settings";
import { APPLICATION_MODE } from "../../app-state";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Replay } from "@mui/icons-material";
import RoundModeMenuItemIcon from "./RoundModeMenuItemIcon";

import "./RoundModeMenuItem.css";

// Default the flash mode
const DEFAULT_TIME = 7000;

/*
  Round times in miliseconds
*/
const roundTimeButtons = [
  { name: "⚡", value: DEFAULT_TIME },
  { name: "30s", value: 30_000 },
  { name: "1m", value: 60_000 },
  { name: "2m", value: 120_000 },
  { name: "5m", value: 300_000 },
  { name: "∞", value: ROUND_TIME_NOLIMIT },
];

/*
    Panel that contains all elements for controlling round mode - starting, stopping, choosing length
 */
export default class RoundModeMenuItem extends Component {
  constructor(properties) {
    super(properties);
    this.state = { roundTime: DEFAULT_TIME };
  }

  handleRoundMode = () => {
    const { circlesSession, applicationMode, toggleMenu } = this.props;
    if (applicationMode === APPLICATION_MODE.NORMAL) {
      circlesSession.startRound(this.state.roundTime);
    } else {
      circlesSession.abortRound();
    }
    toggleMenu();
  };

  render() {
    return (
      <div className="menuItem roundModeMenuItem">
        <div className="menuItemTitle">
          <Replay />
        </div>
        <div className={`roundModePanel ${this.props.applicationMode}-mode`}>
          <ButtonGroup
            orientation="vertical"
            color="primary"
            className="roundLengthSelectPanel"
            aria-label="vertical outlined primary button group"
          >
            {roundTimeButtons.map((radio, index) => (
              <Button
                key={index}
                size="small"
                value={radio.value}
                variant={
                  this.state.roundTime == radio.value ? "contained" : "outlined" // eslint-disable-line eqeqeq
                }
                onClick={(event) =>
                  this.setState({ roundTime: event.currentTarget.value })
                }
              >
                {radio.name}
              </Button>
            ))}
          </ButtonGroup>
          <button
            className={"roundModePanel-startButton-button"}
            onClick={this.handleRoundMode}
          >
            <RoundModeMenuItemIcon applicationMode={this.props.applicationMode}/>
          </button>
        </div>
      </div>
    );
  }
}
