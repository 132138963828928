/*
    Component to draw the room background.
 */

import React, { Component } from "react";
import { RoomSettingsContext } from "./RoomSettingsContext";
import ReactPlayer from "react-player/youtube";
import "./RoomBackground.css";

export default class RoomBackground extends Component {
  static contextType = RoomSettingsContext;

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResized, false);
  }
  windowResized = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    let roomStyle = {};
    if (this.context.bgImage) {
      roomStyle = {
        backgroundImage: "url(" + this.context.bgImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      };
    }
    let videoHeight = this.state.height;
    let videoWidth = this.state.width;
    // If we're given the aspect hint then can calculate video to cover screen...
    if (this.context.bgVideoAspect) {
      const viewAspect = videoWidth / videoHeight;

      if (viewAspect > this.context.bgVideoAspect) {
        videoHeight = videoWidth / this.context.bgVideoAspect;
      } else {
        videoWidth = videoHeight * this.context.bgVideoAspect;
      }
    }

    return (
      <div
        id="bm-page-wrap" // for burger menu
        className={this.props.className}
        style={roomStyle}
        onClick={this.props.onClick}
      >
        {this.context.bgVideo && (
          <div className={"moviePlayBackground "}>
            <ReactPlayer
              url={this.context.bgVideo}
              loop
              playing
              controls={false}
              height={videoHeight}
              width={videoWidth}
              muted={true}
              volume={0}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    loop: 1,
                    disablekb: 1,
                    fs: 0,
                    iv_load_policy: 3,
                  },
                },
              }}
            />
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}
